@import 'colors.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html,
#root,
main {
  height: 100%;
}

.min-h-100 {
  min-height: 100%;
}

.ls-none {
  list-style: none;
}

.btn-circle {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-size: 15px;
  height: 26px;
  justify-content: center;
  padding: 0px;
  width: 26px;
}

.caret {
  cursor: pointer;
}

.caret--active {
  transform: rotate(90deg);
}

.color--purple-400 {
  color: var(--purple-400) !important;
}

.color--pink-200 {
  color: var(--pink-200) !important;
}

.color--pink-500 {
  color: var(--pink-500) !important;
}

.MuiAutocomplete-popper {
  z-index: 999999 !important;
}
